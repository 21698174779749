import React from "react";
import { Select, Spin } from "antd";
import { SelectProps } from "antd/es/select";
import debounce from "lodash/debounce";

export interface DebounceSelectProps<ValueType = any> extends Omit<SelectProps<ValueType>, "options" | "children"> {
  onSearch: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
  placeholder?: string;
}

function DebounceSelect<ValueType extends { key?: string; label: React.ReactNode; value: string | number } = any>({
  onSearch,
  debounceTimeout = 500,
  placeholder = '',
  ...props
}: DebounceSelectProps) {
  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState<ValueType[]>([]);
  const fetchRef = React.useRef(0);

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      onSearch(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [onSearch, debounceTimeout]);

  return (
    <Select<ValueType>
      labelInValue
      placeholder={placeholder}
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
      style={{width: "100%"}}
    />
  );
}

export default DebounceSelect;
