import { Affix, Button, Card, Collapse, List, Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { breakpoint } from "../theme";
import { Input, Select, Tabs } from "antd";
import axios from "axios";
import { SettingOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../store";
import { fetchReports, fetchUnitGroupsIfEmpty, Report } from "../store/reportsSlice";
import { groupBy } from "lodash";
import { Loading } from "../components/Loading";
import CreateReportForm from "../components/CreateReportForm";
import { Link } from "react-router-dom";
import { pushMessage } from "../store/messagesSlice";

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
interface Props {}

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  @media only screen and ${breakpoint.device.sm} {
    flex-direction: row;
  }
`;

const Layout = styled.div`
  margin: 20px;
`;

const StyledLink = styled(Link)`
  max-width: 68%;
  @media only screen and ${breakpoint.device.sm} {
    max-width: 100%;
  }
  
`;

const EmptyReport = { powerbiId: "", powerbiGroupId: "", displayName: "", businessUnitGroup: "", groupName: "", powerbiSrName: "" };
function Dashboard(props: Props) {
  const dispatch = useAppDispatch();
  const [currentReport, setCurrentReport] = useState<Omit<Report, "id">>(null);
  const groupedReports = useAppSelector((state) => state.reports.groupedReports);
  const loading = useAppSelector((state) => state.reports.loading);
  const noReports = useAppSelector((state) => state.reports.noReports);
  const isAdmin = useAppSelector((state) => state.users.currentUser.isAdministrator);
  const groupKeys = Object.keys(groupedReports);

  useEffect(() => {
    dispatch(fetchReports());
    dispatch(fetchUnitGroupsIfEmpty());
  }, [dispatch]);

  useEffect(() => {
    if(noReports){
      dispatch(
        pushMessage({
          message: "Error ",
          description: "Sorry you don’t have access to any reports. Please contact your administrator",
          type: "error",
          timeout: 20,
        })
      );
    }
  }, [noReports]);

  //const dispatch = useDispatch();

  const getImage = (groupName) => <img className="group-logo" src={`/${groupName.toLowerCase().replace(/ /g, '_')}.png`}></img>;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  //const onFilterChangeDebounced = useCallback(debounce(onFilterChange, 500), []);

  if (loading) return <Loading />;

  return (
    <Layout>
      {isAdmin && (
        <Button style={{ margin: 10 }} type="primary" onClick={() => setCurrentReport(EmptyReport)}>
          Create Report
        </Button>
      )}
      <CreateReportForm onClose={() => setCurrentReport(null)} report={currentReport} />
      <Collapse
        style={{ flex: 1 }}
        defaultActiveKey={JSON.parse(localStorage.getItem("tabs"))}
        onChange={(openedTabs) => localStorage.setItem("tabs", JSON.stringify(openedTabs))}
        expandIconPosition="right"
      >
        {groupKeys.map((groupName) => (
          <Collapse.Panel header={getImage(groupName)} key={groupName}>
            <List
              dataSource={groupedReports[groupName]}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    isAdmin ? (
                      <Button onClick={() => setCurrentReport(item)} key="list-loadmore-edit">
                        Edit
                      </Button>
                    ) : null,
                  ]}
                >
                  <StyledLink to={`/reports/${item.id}`}>{item.displayName}</StyledLink>
                </List.Item>
              )}
            />
          </Collapse.Panel>
        ))}
      </Collapse>
    </Layout>
  );
}

export default Dashboard;
