import React, { useEffect } from "react";
import styled from "styled-components";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import { Button } from "antd";
import { useAppSelector } from "../store";
import { breakpoint } from "../theme";
import DebounceSelect from "./DebounceSelect";
import axios from "axios";
import { useMsal } from "@azure/msal-react";

const color = "#393B40";

const StyledHeader = styled.header`
  font-size: 1.2em;
  background-color: white;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  color: ${color};
  height: 50px;
  flex-wrap: wrap;
`;

const StyledActions = styled.div`
  display: flex;
  flex-direction: column;
  order: 1;
  flex: 40%;
  max-width: 40%;
  @media${breakpoint.device.sm} {
    flex-direction: row;
    order: 2;
    flex: auto;
    max-width: max-content;
  }
`;

const ImpersonateContainer = styled.div`
  flex: 100%;
  max-width: 100%;
  order: 2;
  margin: 10px 0 0 0;
  @media${breakpoint.device.sm} {
    flex: 30%;
    max-width: 30%;
    order: 1;
    margin: 0 20px 0 auto;
  }
`;

const StyledBrand = styled.div`
  text-align: left;
  cursor: pointer;
  align-self: center;
  order: 0;
  flex: 60%;
  max-width: 60%;
  @media${breakpoint.device.sm} {
    flex: auto;
    max-width: max-content;
  }
`;

const ImpersonateHeader = styled.div`
  width: 100%;
  background-color: var(--orange);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  @media${breakpoint.device.sm} {
    flex-direction: row;
    padding-top: 0px;
  }
`;

interface Props {}

const searchAdUser = (userName) => {
  return axios.get(`/ad-users?filter=${userName}`).then((res) => res.data.map((e) => ({ label: e.userPrincipalName, value: e.userPrincipalName })));
};

function Header(props: Props) {
  const history = useHistory();
  const isAdmin = useAppSelector((state) => state.users.currentUser.isAdministrator);
  const impersonate = localStorage.getItem("ActAs") ? JSON.parse(localStorage.getItem("ActAs")) : "";
  const { instance } = useMsal();

  const onChange = ({ value }) => {
    localStorage.setItem("ActAs", JSON.stringify(value));
    window.location.reload();
  };

  const stopImpersonation = () => {
    localStorage.removeItem("ActAs");
    window.location.reload();
  };

  return (
    <div>
      {impersonate && impersonate !== "" && (
        <ImpersonateHeader>
          <span style={{ fontSize: "14px", fontWeight: 600 }}>Impersonating {impersonate}</span>
          <Button onClick={stopImpersonation} style={{ margin: 10 }}>
            Stop Impersonating
          </Button>
        </ImpersonateHeader>
      )}
      <StyledHeader>
        <StyledBrand
          onClick={() => {
            //if navigated from dashboard history.action will be PUSH
            return history.push("/");
          }}
        >
          OnPoint Report Center
        </StyledBrand>
        {isAdmin && (!impersonate || impersonate === "") && (
          <ImpersonateContainer>
            <DebounceSelect showSearch onSearch={searchAdUser} onChange={onChange} placeholder="Search user to impersonate" />
          </ImpersonateContainer>
        )}
        <StyledActions>
          {isAdmin && (
            <Button onClick={() => history.push("/users")} icon={<UserOutlined />} style={{ marginRight: 20, marginBottom: 10, width: "100%" }}>
              Users
            </Button>
          )}
          <Button onClick={() => instance.logoutRedirect()} type="primary" icon={<LogoutOutlined />} size="middle">
            Logout
          </Button>
        </StyledActions>
      </StyledHeader>
    </div>
  );
}

export default Header;
