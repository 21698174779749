import { Spin } from "antd";
import React from "react";
import styled from "styled-components";
import Messages from "./Messages";

const CenteredDiv = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
`;

export const Loading = () => (
  <CenteredDiv>
    <Spin size="large" />
    <Messages />
  </CenteredDiv>
);
