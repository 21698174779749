export function ObjectToQueryParam(params: Object): string {
  return (
    Object.keys(params)
      //@ts-ignore
      .filter((key) => params[key])
      //@ts-ignore
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join("&")
  );
}

export function formatDate(dateString: string) {
  return new Date(dateString).toLocaleString();
}

export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return [s4(), s4(), "-", s4(), "-", s4(), "-", s4(), "-", , s4(), s4(), s4()].join("").toLowerCase();
}
