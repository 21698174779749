import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../store";
import { fetchReportEmbedToken } from "../store/reportsSlice";
import { Loading } from "../components/Loading";
import { Redirect, useParams } from "react-router-dom";
import * as pbi from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import { EmbedTokenResponse } from "../store/reportsSlice";
import { breakpoint } from "../theme";

interface Props {}

const Layout = styled.div`
  height: calc(100vh - 50px);
`;

function Report() {
  const dispatch = useAppDispatch();
  const [report, setReport] = useState<pbi.Report>();
  const [embedToken, setEmbedToken] = useState<EmbedTokenResponse>(null);
  const isLoading = useAppSelector((state) => state.reports.embedTokenLoading);
  let { id } = useParams<{ id: string }>();

  let embedConfiguration = {};

  if (!!embedToken) {
    // PowerBI Report object (to be received via callback)
    embedConfiguration = {
      type: "report",
      id: embedToken.id,
      embedUrl: embedToken.embedUrl,
      tokenType: pbi.models.TokenType.Embed,
      accessToken: embedToken.embedToken.token,
      permissions: pbi.models.Permissions.Copy,
      viewMode: pbi.models.ViewMode.View,
    };
  }

  useEffect(() => {
    dispatch(fetchReportEmbedToken(id)).then(({ payload }) => setEmbedToken(payload));
  }, [dispatch, id]);

  if (embedToken === undefined && !isLoading) return <Redirect to="/" />;

  if (!embedToken || isLoading) return <Loading />;
  return (
    <Layout>
      {
        <PowerBIEmbed
          embedConfig={embedConfiguration}
          eventHandlers={
            new Map([
              [
                "loaded",
                function () {
                  console.log("Report loaded");
                },
              ],
              [
                "rendered",
                function () {
                  console.log("Report rendered");
                },
              ],
              [
                "error",
                function (event) {
                  console.log(event.detail);
                },
              ],
            ])
          }
          cssClassName={"report-style-class"}
          getEmbeddedComponent={(embeddedReport) => {
            setReport(embeddedReport as pbi.Report);
          }}
        />
      }
    </Layout>
  );
}

export default Report;
