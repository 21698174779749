import React, { FC, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Report from "./pages/Report";
import NotFound from "./pages/NotFound";
import Header from "./components/Header";
import Messages from "./components/Messages";
import { InteractionType } from "@azure/msal-browser";
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate, MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { Button } from "antd";
import { Loading } from "./components/Loading";
import Users from "./pages/Users";
import { useAppDispatch, useAppSelector } from "./store";
import { fetchCurrentUser, setUser } from "./store/usersSlice";
import { useDispatch } from "react-redux";
import useInterceptors from "./useInterceptors";
import useConfigLoader from "./hooks/useConfigLoader";
import useAccessToken from "./hooks/useAccessToken";

let App = ({ envConfig }: { envConfig: any}) => {
  const dispatch = useDispatch();
  const token = useAccessToken(envConfig);
  const isReady = useInterceptors({ envConfig, token });
  const isLoading = useAppSelector((state) => state.users.loadingCurrentUser);
  const impersonate =  localStorage.getItem('ActAs') ? JSON.parse(localStorage.getItem('ActAs')) : ""; 
  const userName = useAppSelector((state) => state.users.username);

  
  useEffect(() => {
    if(!isReady) return; 
    if(impersonate && impersonate !== ""){
      dispatch(setUser(impersonate));
    } 

    if (userName) dispatch(fetchCurrentUser(userName));
  }, [userName, dispatch, impersonate, isReady]);

  if (isLoading || !isReady) return <Loading />;

  return (
    <>
      <Header />
      <Messages />
      <Switch>
        <Route exact path="/">
          <Dashboard />
        </Route>
        <Route path="/users">
          <Users />
        </Route>
        <Route path="/reports/:id">
          <Report />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </>
  );
};
App = React.memo(App);

const AppSecurity: FC = () => {
  const {isConfigLoaded, envConfig, authConfig} = useConfigLoader();
  const { instance } = useMsal();

  return ( isConfigLoaded && 
    <MsalProvider instance={authConfig}>
        <AuthenticatedTemplate>
          <App envConfig={envConfig}/>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
            <span>An error occured during authentication, please try again</span>
            <p>
              <Button onClick={() => instance.loginRedirect()}>Login</Button>
            </p>
        </UnauthenticatedTemplate>
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        </MsalAuthenticationTemplate>
    </MsalProvider> 
  );
};

const AppWithRouter = () => {
  return (
    <Router>
      <AppSecurity />
    </Router>
  );
};
export default AppWithRouter;
