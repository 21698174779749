import { Card, Skeleton } from "antd";
import React, { ReactNode } from "react";
import styled from "styled-components";

const CardWrapper = styled.div`
  margin: 1rem;
  padding: 2rem;
  border-radius: 10px;
  background-color: white;
`;

const StyledCard = styled(Card)`
  margin: 1rem;
  padding: 2rem;
  border-radius: 10px;
  background-color: white;
`;

function BlankCard({ style, loading, children }: { style?: React.CSSProperties; children: ReactNode; loading?: boolean }) {
  return (
    <CardWrapper style={style}>
      <Skeleton active loading={loading}>
        {children}
      </Skeleton>
    </CardWrapper>
  );
}

export default BlankCard;
