import React, { useState, useEffect } from "react";
import axios from "axios";
import { PublicClientApplication, Configuration } from "@azure/msal-browser";

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

function useConfigLoader() {
  const [config, setConfig] = useState(null);
  const [isConfigLoaded, setIsConfigLoaded] = useState(false);
  const [authConfig, setAuthConfig] = useState(null);

  useEffect(() => {
    if(isConfigLoaded) return;
    
    axios
      .get(`../env.json`)
      .then(function (response) {
        // Msal Configurations
        const conf: Configuration = {
          auth: {
            authority: response.data.auth.authority,
            clientId: response.data.auth.clientId,
            redirectUri: window.location.origin,
            postLogoutRedirectUri: "/"
          },
          cache: {
            storeAuthStateInCookie: isIE || isEdge || isFirefox
          },
        };

        const auth = new PublicClientApplication(conf);
        setAuthConfig(auth);
        setConfig(response.data);
        setIsConfigLoaded(true);
      })
      .catch(function (error) {
        console.log("Could not load application configuration.");
        console.log(error);
      });
    }, [isConfigLoaded]);

  return {isConfigLoaded: isConfigLoaded, envConfig: config, authConfig: authConfig}; 
}

export default useConfigLoader;