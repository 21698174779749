import { Button, Form, Input, Modal, Popconfirm, Select } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { createUser, deleteUser, fetchAdUsers, fetchCurrentUser, fetchUser, fetchUsers, updateUser, User } from "../store/usersSlice";

import { useAppDispatch, useAppSelector } from "../store";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { fetchReportsIfEmpty } from "../store/reportsSlice";
import styled from "styled-components";
import { breakpoint } from "../theme";
import { debounce } from "lodash";
import DebounceSelect from "./DebounceSelect";
import axios from "axios";

const Row = styled.div`
  width: 100%;
  @media${breakpoint.device.sm} {
    width: auto;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-bottom: 20px;
  @media${breakpoint.device.sm} {
    width: auto;
  }
`;

interface Props {
  userName: string;
  onClose: () => void;
}
const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 19 },
};
const emptyUser = {
  isAdministrator: false,

  reportIds: [],
  userName: "",
};

const searchAdUser = (userName) => {
  return axios.get(`/ad-users?filter=${userName}`).then((res) => res.data.map((e) => ({ label: e.userPrincipalName, value: e.userPrincipalName })));
};

function CreateUserForm({ userName, onClose }: Props) {
  const dispatch = useAppDispatch();
  const [user, setUser] = useState<User>(null);
  const reports = useAppSelector((state) => state.reports.data);
  const loading = useAppSelector((state) => state.users.loadingUser);
  //const adUsers = useAppSelector((state) => state.users.adUsers);
  const currentUserUsername = useAppSelector((state) => state.users.currentUser.userName);
  const refetchUsers = () => dispatch(fetchUsers());

  useEffect(() => {
    setUser(emptyUser);
    if (userName) {
      dispatch(fetchUser(userName)).then(({ payload }) => setUser(payload));
    } else {
      setUser(null);
    }
  }, [dispatch, userName]);

  useEffect(() => {
    dispatch(fetchReportsIfEmpty());
    //dispatch(fetchAdUsers());
  }, [dispatch]);
  const onFinish = (data) => {
    //   console.log(data, report);
    const payload = {
      ...user,
      ...data,
    };

    if (typeof payload.userName === "object") {
      payload.userName = payload.userName.value;
    }
    if (userName) {
      return dispatch(updateUser(payload)).then((_) => {
        //if we are editing our info we are refetching currentUser
        if (userName === currentUserUsername) {
          dispatch(fetchCurrentUser(currentUserUsername));
        }
        refetchUsers();
        onClose();
      });
    }
    return dispatch(createUser(payload)).then((_) => {
      refetchUsers();
      onClose();
    });
  };

  const onDelete = () => {
    if (userName) {
      dispatch(deleteUser(userName)).then((_) => {
        refetchUsers();
        onClose();
      });
    }
  };

  return (
    <Row>
      <StyledButton type="primary" onClick={() => setUser(emptyUser)}>
        Create User
      </StyledButton>
      <Modal
        width={600}
        visible={!!user}
        footer={null}
        onCancel={() => {
          setUser(null);
          onClose();
        }}
      >
        <Form
          ref={(e) => e?.resetFields()}
          style={{ margin: 20 }}
          {...layout}
          name="basic"
          initialValues={{
            isAdministrator: user?.isAdministrator,
            reportIds: user?.reportIds,
            userName: user?.userName,
          }}
          onFinish={onFinish}
        >
          <Form.Item label="Username" name="userName">
            {!userName ? <DebounceSelect showSearch onSearch={searchAdUser} /> : userName}
          </Form.Item>
          <Form.Item valuePropName="checked" label="Is Admin" name="isAdministrator">
            <Checkbox />
          </Form.Item>

          <Form.Item label="Reports" name="reportIds" rules={[{ required: false }]}>
            <Select mode="multiple" style={{ width: "100%" }} placeholder="Select Reports">
              {reports.map((e) => (
                <Select.Option key={e.id} value={e.id} title={e.displayName + e.id}>
                  <span style={{ fontSize: 12 }}>
                    {e.displayName} {e.id}
                  </span>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button loading={loading} type="primary" htmlType="submit">
              Submit
            </Button>
            {userName && (
              <Popconfirm
                title="Are you sure to delete this user?"
                onConfirm={onDelete}
                okText="Yes"
                cancelText="No"
              >
                <Button style={{ marginLeft: 10 }} loading={loading} type="dashed" htmlType="reset">
                  Delete
                </Button>
              </Popconfirm>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </Row>
  );
}

export default CreateUserForm;
